import styled from "@emotion/styled";

const StyledBrandCard = styled.a<{ hasSaleExpired?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  overflow: hidden;
  pointer-events: ${({ hasSaleExpired }) => (hasSaleExpired ? "none" : "auto")};
  cursor: pointer;

  :focus,
  :active {
    :before {
      width: 100%;
      height: 100%;
      content: "";
      position: absolute;
      border-radius: 8px;
      top: 0;
      left: 0;
      z-index: 12;
      box-shadow: inset 0px 0px 44px rgba(0, 0, 0, 0.15);
    }
  }
  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    :hover {
      filter: drop-shadow(0px 8px 24px rgba(0, 0, 0, 0.1));
    }
  }
`;

const StyledBrandCardImageWrapper = styled.div<{
  isBottomRadius: boolean;
  noGradient?: boolean;
}>`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.tone.silver};
  border-radius: ${({ isBottomRadius }) =>
    isBottomRadius ? "8px" : "8px 8px 0 0"};

  :before {
    width: 100%;
    height: 48%;
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1;
    opacity: 0.6;
    background-image: ${({ noGradient }) =>
      noGradient
        ? "none"
        : "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100% )"};
    ${({ isBottomRadius }) => isBottomRadius && "border-radius: 0 0 8px 8px"};
  }
`;

const StyledBrandCardImage = styled.div<{
  imageMobile: string;
  imageDesktop: string;
  isBottomRadius: boolean;
  hovered?: boolean;
  isGated?: boolean;
  isMobile?: boolean;
}>`
  width: 100%;
  height: 100%;
  background-image: ${({ imageMobile }) => `url(${imageMobile})`};
  background-position: top;
  background-size: cover;
  border-radius: ${({ isBottomRadius }) =>
    isBottomRadius ? "8px" : "8px 8px 0 0"};
  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    background-image: ${({ imageDesktop }) => `url(${imageDesktop})`};
  }

  filter: ${({ isGated, isMobile, hovered }) =>
    !isMobile && isGated && hovered ? "blur(8px)" : "none"};
`;

const StyledBrandCardLogo = styled.img`
  position: absolute;
  top: 75%;
  left: 0;
  width: 100%;
  height: 50%;
  object-fit: cover;
  transform: translateY(-50%);
  z-index: 2;
`;

const StyledBrandCardInfo = styled.div`
  width: 100%;
  padding: 16px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1 1 auto;

  background: ${({ theme }) => theme.colors.tone.white};
  border: 1px solid #eeeeee;
  border-top: none;
`;

const StyledBrandCardInfoText = styled.div`
  display: flex;
  flex: 1;
`;

const StyledBrandCardLineClamp = styled.span`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
`;

const StyledBrandCardInfoLabelWrapper = styled.div`
  display: inline-block;
  position: relative;
  bottom: 4px;
  margin-right: 8px;
`;

export {
  StyledBrandCard,
  StyledBrandCardImage,
  StyledBrandCardImageWrapper,
  StyledBrandCardLogo,
  StyledBrandCardInfo,
  StyledBrandCardInfoText,
  StyledBrandCardLineClamp,
  StyledBrandCardInfoLabelWrapper,
};
